<div class="d-flex justify-content-center flex-fill" *ngIf="isLoading">
    <div
        id="skLoadingSpinner"
        class="sk-spin-loader animation-pause my-auto"
        translate="COMMON.LOADING"
    ></div>
</div>
<div class="sk-page-content" *ngIf="!isLoading">
    <div
        *ngIf="skShowRequestAccessPage"
        class="row mx-0 my-100 justify-content-center w-100"
    >
        <div class="col col-sm-4 text-center">
            <h3 class="mt-5 mb-2" translate="LANDING.MARKETPLACE_TITLE"></h3>
            <p
                class="sk-body-display-1"
                translate="LANDING.MARKETPLACE_DESCRIPTION"
            ></p>
            <button
                type="button"
                class="btn btn-primary mt-100"
                (click)="skShowRequestAccessPage = false"
            >
                {{ "LANDING.OK_GOT_IT" | translate }}
            </button>
        </div>
    </div>
    <div *ngIf="!skShowRequestAccessPage" class="row flex-fill mx-0">
        <form
            [formGroup]="skMarketplaceConfigForm"
            novalidate
            (ngSubmit)="onSubmit(skPublished)"
            class="row col m-100"
            *ngIf="skMarketplaceConfigForm"
        >
            <div class="col-lg-7 mr-auto px-0">
                <h3
                    class="col-12 card-title mt-100 mb-200 p-0"
                    translate="LANDING.CUSTOMIZE_MARKETPLACE_TITLE"
                ></h3>
                <div class="col-12 p-0 mb-200">
                    <div class="col-6 p-0">
                        <label
                            translate="COMMON.PAGE_TITLE"
                            class="font-medium"
                            for="skWhiteLabelCloudBackupPageTitleInput"
                        ></label>
                        <input
                            class="form-control"
                            id="skWhiteLabelCloudBackupPageTitleInput"
                            formControlName="skPageTitle"
                            name="skPageTitle"
                            type="text"
                        />
                    </div>
                </div>
                <hr class="b-gray-8 col-12 px-0" />
                <sk-custom-url
                    class="col-12 p-0 mb-200"
                    [skCustomUrl]="
                        skMarketplaceConfigForm.controls['skCustomUrl']
                    "
                    [skSubdomainHostName]="'.configureyourorder.com'"
                >
                </sk-custom-url>
                <hr class="b-gray-8 col-12 px-0" />
                <sk-customer-support
                    class="col-12 p-0 mb-200"
                    [skCustomerSupport]="
                        skMarketplaceConfigForm.controls['skCustomerSupport']
                    "
                ></sk-customer-support>
                <hr class="b-gray-8 col-12 px-0" />
                <div class="col-12 p-0 mb-200">
                    <div class="col-12 p-0">
                        <h4 class="card-title">
                            <span
                                translate="LANDING.MARKETPLACE_GRANT_ACCESS_TITLE"
                            ></span>
                            <button
                                type="button"
                                href="#"
                                class="btn sk-btn-icon sk-btn-inline-icon bd-popover border-0"
                                [ngbTooltip]="
                                    'WHITELABEL.MARKETPLACE_CUSTOMER_ADMIN_ACCESS_HELP'
                                        | translate
                                "
                                placement="right"
                            >
                                <i class="material-icons md-22 sk-help"
                                    >help_outline</i
                                >
                            </button>
                        </h4>
                        <label
                            translate="LANDING.MARKETPLACE_GRANT_ACCESS"
                            class="my-50"
                        ></label>
                        <div class="sk-onoffswitch">
                            <input
                                class="sk-onoffswitch-checkbox"
                                id="skWhiteLabelMarketplaceConfigGrantAccessInput"
                                formControlName="skGrantAccessPermission"
                                name="skGrantAccessPermission"
                                type="checkbox"
                            />
                            <label
                                class="sk-onoffswitch-label"
                                for="skWhiteLabelMarketplaceConfigGrantAccessInput"
                            >
                                <span class="sk-onoffswitch-icon-inner"></span>
                                <span class="sk-onoffswitch-switch"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <hr class="b-gray-8 col-12 px-0" />
                <div class="col p-0 mb-100">
                    <button class="btn btn-primary" type="submit">
                        {{
                            (skFirstSave ? "COMMON.SAVE" : "COMMON.UPDATE")
                                | translate
                        }}
                    </button>
                </div>
            </div>
            <hr class="b-gray-8 col-lg-12 px-0 d-lg-none" />
            <sk-publish
                class="col-lg-auto px-0"
                [skFirstSave]="skFirstSave"
                [skPublished]="skPublished"
                (publish)="onSubmit(true)"
                (preview)="onPreview()"
                (unpublish)="onSubmit(false)"
            ></sk-publish>
        </form>
    </div>
</div>
