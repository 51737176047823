<div class="modal-content text-center">
    <div class="modal-body">
        <div class="sk-example p-a-3 m-b-2 text-center">
            <img
                src="../../../../assets/images/full-width-sample.jpg"
                class="rounded"
                width="98%"
            />
        </div>

        <h3 class="text-xs-center mt-3 mb-3 px-3">
            {{ "WELCOME_MODAL.TITLE" | translate }}
        </h3>
        <p class="sk-body-display card-text text-xs-center mb-3 px-3">
            {{ "WELCOME_MODAL.TEXT" | translate }}
        </p>

        <div class="row">
            <dl class="sk-dl col-sm-10 col-sm-offset-1"></dl>
        </div>
    </div>
    <!-- /.modal-body -->
    <div class="modal-footer d-flex justify-content-center">
        <button
            type="button"
            class="btn btn-primary center-block"
            data-bs-dismiss="modal"
            id="skSyndicationWelcomeModal"
            (click)="savePreviewModalState()"
        >
            {{ "WELCOME_MODAL.BUTTON_TEXT" | translate }}
        </button>
    </div>
    <!-- /.modal-footer -->
</div>
