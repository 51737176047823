import { Routes } from "@angular/router";

export const ApplicationRoutes: Routes = [
    { path: "", redirectTo: "brandedservices", pathMatch: "full" },
    {
        path: "brandedservices",
        loadChildren: () =>
            import("./syndication/whitelabel/whitelabel.module").then(
                (m) => m.WhiteLabelModule
            ),
    },
];
