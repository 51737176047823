import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { BackupPartnerSettingsRequest } from "../../../shared/models/BackupPartnerSettingsRequest";
import { Certificate } from "../../../shared/models/Certificate";
import { SupportDetails } from "../../../shared/models/SupportDetails";
import { FileUploadService } from "../../../shared/services/file-upload.service";
import { WhiteLabelDataService } from "../services/whitelabel-data.service";
import { CustomUrlSelection } from "../shared/custom-url/custom-url.component";
import { SupportOption } from "../shared/customer-support/customer-support.component";
import { CloudBackupFormModel } from "./cloud-backup-form.model";

@Injectable({
    providedIn: "root",
    deps: [FileUploadService],
})
export class CloudBackupService {
    constructor(
        private readonly whiteLabelDataService: WhiteLabelDataService,
        private readonly fileUploadService: FileUploadService
    ) {}

    public async getBackupWhiteLabelSettings(): Promise<CloudBackupFormModel | null> {
        const backupPartnerSettings = await lastValueFrom(
            this.whiteLabelDataService.getBackupSettings()
        );
        if (!backupPartnerSettings) {
            return null;
        }

        // Build CustomUrl section of the Form model.
        const pageUrl: string = backupPartnerSettings.pageUrl || "";
        //
        // We limit the range of custom subdomain to 50. Because Azure has a limit of 89 characters for a domain name.
        // Therefore after .backupmycloud.com has been appended we only have 71 characters available.
        //
        const skCustomSubDomainRegex: RegExp =
            /^(https:\/\/)([a-zA-Z0-9-]{1,50})\.backupmycloud\.com\/?$/;
        const skCustomSubDomainNameRegexGroupIndex = 2;
        const subDomainUrlMatches: RegExpMatchArray | null = pageUrl.match(
            skCustomSubDomainRegex
        );
        const skCustomUrl = {
            skCustomUrlSelection: (subDomainUrlMatches
                ? "subdomain"
                : "domain") as CustomUrlSelection,
            skDomainName: subDomainUrlMatches ? "" : pageUrl,
            skSubDomainName: subDomainUrlMatches
                ? subDomainUrlMatches[skCustomSubDomainNameRegexGroupIndex]
                : "",
            skCertificate: backupPartnerSettings.domainCertificate || null,
            skSelectedSslCert: null,
            skSslPassphrase: "",
        };

        // Build CustomerSupport section of the Form model.
        const supportDetails: SupportDetails | undefined =
            backupPartnerSettings.supportDetails;
        const supportOption: SupportOption = backupPartnerSettings.supportUrl
            ? "support-url"
            : "support-details";
        const skCustomerSupport = {
            skSupportOptions: supportOption,
            skSupportEmail: supportDetails ? supportDetails.emailAddress : "",
            skSupportPhone: supportDetails ? supportDetails.phoneNumber : "",
            skSupportHours: supportDetails ? supportDetails.hours : "",
            skSupportUrl: backupPartnerSettings.supportUrl ?? "",
        };

        // Put together the form model.
        const formModel: CloudBackupFormModel = {
            skPageTitle: backupPartnerSettings.pageTitle ?? "",
            skCustomUrl,
            skCustomerSupport,
            skLearnMoreUrl: backupPartnerSettings.learnMoreUrl ?? "",
            skIsEnabled: backupPartnerSettings.isEnabled ?? false,
        };

        return formModel;
    }

    public async saveBackupWhiteLabelSettings(
        newSettings: CloudBackupFormModel,
        publish: boolean
    ): Promise<Certificate | null> {
        // Determine the pageUrl
        const isSubDomainOption =
            newSettings.skCustomUrl.skCustomUrlSelection === "subdomain";
        const subDomainUrl = `https://${newSettings.skCustomUrl.skSubDomainName}.backupmycloud.com/`;
        const domainUrl = newSettings.skCustomUrl.skDomainName;
        const pageUrl: string = isSubDomainOption ? subDomainUrl : domainUrl;
        const certData: string | undefined = isSubDomainOption
            ? undefined
            : btoa(
                  await this.fileUploadService.readAllBytes(
                      newSettings.skCustomUrl.skSelectedSslCert
                  )
              );
        const domainCertificate: Certificate | undefined = isSubDomainOption
            ? undefined
            : {
                  passphrase: newSettings.skCustomUrl.skSslPassphrase,
              };

        // Determine Support Option
        const isSupportUrlOption: boolean =
            newSettings.skCustomerSupport.skSupportOptions === "support-url";
        const supportDetails: SupportDetails | undefined = isSupportUrlOption
            ? undefined
            : {
                  emailAddress: newSettings.skCustomerSupport.skSupportEmail,
                  phoneNumber: newSettings.skCustomerSupport.skSupportPhone,
                  hours: newSettings.skCustomerSupport.skSupportHours,
              };
        const supportUrl: string | undefined = isSupportUrlOption
            ? newSettings.skCustomerSupport.skSupportUrl
            : undefined;

        // Build the request object
        const backupPartnerSettingsRequest: BackupPartnerSettingsRequest = {
            certData,
            backupWhiteLabelSettings: {
                domainCertificate,
                isEnabled: publish,
                pageTitle: newSettings.skPageTitle,
                pageUrl,
                supportDetails,
                supportUrl,
                learnMoreUrl: newSettings.skLearnMoreUrl || "",
            },
        };

        return lastValueFrom(
            this.whiteLabelDataService.saveBackupSettings(
                backupPartnerSettingsRequest
            )
        );
    }
}
