<div sk-page-header>
    <h3>{{ "COMMON.WHITELABEL_FEATURE_NAME" | translate }}</h3>
    <ul sk-page-header-nav class="sk-tabs-lg d-flex d-none">
        <li
            *ngFor="let page of Pages"
            sk-page-header-nav-item
            [link]="page.Route"
            [label]="page.Name"
        ></li>
    </ul>
</div>

<div class="sk-page-content">
    <!-- PANEL LEFT PRIMARY - SIDENAV -->
    <div sk-page-panel name="primary" class="sk-panel-primary"></div>

    <!-- CONTENT COLUMN -->
    <router-outlet></router-outlet>

    <!-- PANEL RIGHT -->
    <div sk-panel [hidden]="true" #panel></div>
</div>
