<div class="d-flex justify-content-center flex-fill" *ngIf="isLoading">
    <div
        id="skLoadingSpinner"
        class="sk-spin-loader animation-pause my-auto"
        translate="COMMON.LOADING"
    ></div>
</div>
<div class="sk-page-content" *ngIf="!isLoading">
    <div
        *ngIf="skShowRequestAccessPage"
        class="row mx-0 my-100 justify-content-center w-100"
    >
        <div class="col text-center col-lg-auto">
            <!-- <div class="embed-responsive embed-responsive-16by9">
                TODO: Add video that covers the features of Cloud Backup White Labeling.
                <iframe id="skWhiteLabelCloudBackupVideoIFrame" class="embed-responsive-item"
                    src="" allowfullscreen>
                </iframe>
            </div> -->
            <h3 class="mt-100" translate="LANDING.BACKUP_TITLE"></h3>
            <span
                class="sk-body-display-1"
                translate="LANDING.BACKUP_DESCRIPTION"
            ></span>
            <div class="sk-body-display-1">
                <!-- <a id="skWhiteLabelCloudBackupLearnMoreLink" href="#"
                    translate="COMMON.LEARN_MORE"></a> -->
            </div>
            <a
                id="skWhiteLabelCloudBackupRequestAccessButton"
                role="button"
                class="btn btn-primary mt-100"
                href="mailto://syndication-support@skykick.com"
                translate="COMMON.REQUEST_ACCESS"
            ></a>
        </div>
    </div>
    <div *ngIf="!skShowRequestAccessPage" class="row flex-fill mx-0">
        <form
            [formGroup]="skCloudBackupForm"
            novalidate
            (ngSubmit)="onSubmit(skPublished)"
            class="row col m-100"
            *ngIf="skCloudBackupForm"
        >
            <div class="col-lg-7 mr-auto px-0">
                <h3
                    class="col-12 card-title mt-100 mb-200 p-0"
                    translate="LANDING.CUSTOMIZE_BACKUP_TITLE"
                ></h3>
                <sk-feedback
                    *ngIf="isInformationFeedbackVisible"
                    class="my-150"
                    [subject]="
                        'LANDING.CUSTOMERS_LOGIN_INFO_FEEDBACK.SUBJECT'
                            | translate
                    "
                    type="info"
                    (closed)="handleCloseFeedback()"
                    [dismissible]="true"
                    [message]="
                        'LANDING.CUSTOMERS_LOGIN_INFO_FEEDBACK.MESSAGE'
                            | translate
                    "
                ></sk-feedback>
                <div class="col-12 p-0 mb-200">
                    <div class="col-6 p-0">
                        <label
                            translate="COMMON.PAGE_TITLE"
                            class="font-medium"
                            for="skWhiteLabelCloudBackupPageTitleInput"
                        ></label>
                        <input
                            class="form-control"
                            id="skWhiteLabelCloudBackupPageTitleInput"
                            formControlName="skPageTitle"
                            name="skPageTitle"
                            type="text"
                        />
                    </div>
                </div>
                <hr class="b-gray-8 col-12 px-0" />
                <sk-custom-url
                    class="col-12 p-0 mb-200"
                    [skCustomUrl]="skCloudBackupForm.controls['skCustomUrl']"
                    [skSubdomainHostName]="'.backupmycloud.com'"
                >
                </sk-custom-url>
                <hr class="b-gray-8 col-12 px-0" />
                <sk-customer-support
                    class="col-12 p-0 mb-200"
                    [skCustomerSupport]="
                        skCloudBackupForm.controls['skCustomerSupport']
                    "
                ></sk-customer-support>
                <hr class="b-gray-8 col-12 px-0" />
                <div class="col-12 p-0 mb-200">
                    <h4 class="card-title">
                        <span
                            class="font-strong"
                            translate="WIDGETS.CUSTOMER_SUPPORT_LABEL"
                        ></span>
                        <button
                            type="button"
                            href="#"
                            class="btn sk-btn-icon sk-btn-inline-icon bd-popover border-0"
                            [ngbTooltip]="
                                'WIDGETS.CUSTOMER_SUPPORT_HELP_LABEL'
                                    | translate
                            "
                            placement="right"
                        >
                            <i class="material-icons md-22 sk-help"
                                >help_outline</i
                            >
                        </button>
                    </h4>
                    <div class="col-6 p-0 mt-50">
                        <label
                            translate="WIDGETS.CUSTOMER_SUPPORT_INPUT_LABEL"
                            class="font-medium mb-50"
                            for="skWhiteLabelLearnMoreUrlInput"
                        ></label>
                        <input
                            class="form-control"
                            id="skWhiteLabelLearnMoreUrlInput"
                            formControlName="skLearnMoreUrl"
                            name="skLearnMoreUrl"
                            type="url"
                            [placeholder]="
                                'WIDGETS.CUSTOMER_SUPPORT_INPUT_LABEL_HINT'
                                    | translate
                            "
                        />
                    </div>
                </div>
                <hr class="b-gray-8 col-12 px-0" />
                <div class="col p-0 mb-100">
                    <button class="btn btn-primary" type="submit">
                        {{
                            (skFirstSave ? "COMMON.SAVE" : "COMMON.UPDATE")
                                | translate
                        }}
                    </button>
                </div>
            </div>
            <hr class="b-gray-8 col-lg-12 px-0 d-lg-none" />
            <sk-publish
                class="col-lg-auto px-0"
                [skFirstSave]="skFirstSave"
                [skPublished]="skPublished"
                (publish)="onSubmit(true)"
                (preview)="onPreview()"
                (unpublish)="onSubmit(false)"
            ></sk-publish>
        </form>
    </div>
</div>
