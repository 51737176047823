import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class FileUploadService {
    async readAllBytes(file: File | null): Promise<string> {
        if (!file) {
            return Promise.resolve("");
        }

        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onloadend = (_) => {
                if (reader.result) {
                    resolve(
                        String.fromCharCode.apply(
                            null,
                            new Uint8Array(reader.result as ArrayBuffer)
                        )
                    );
                } else {
                    reject(reader.error);
                }
            };
        });
    }
}
