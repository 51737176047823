import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class InitializationService {
    constructor(
        private readonly userProvider: AbstractUserProvider,
        private readonly translateService: TranslateService
    ) {}

    async run(): Promise<void> {
        ///
        /// WARNING: This is intentionally undeveloped.  Entire
        ///          login page redirection and JWT validation
        ///          system needs a real design.
        ///          CM #76699 / PS #70222
        ///

        const portalPageThatWillRedirectToLoginPage = this.getLoginRedirect();

        try {
            this.userProvider.getCurrentUser();
            return Promise.resolve();
        } catch (e) {
            // The currently logged in user doesn't exist or their session has expired
            const loginExpiredTranslation: string = await lastValueFrom(
                this.translateService.get("ERRORS.LOGIN_EXPIRED")
            );
            alert(loginExpiredTranslation);
            window.location.href = portalPageThatWillRedirectToLoginPage;
            return Promise.reject(
                new Error("User is not logged into the portal")
            );
        }
    }

    getLoginRedirect(): string {
        const currentUrl = window.location.href.toLocaleLowerCase();

        // intentionally hard-coded and bad - see warning above.
        if (currentUrl.includes("localhost")) {
            return "http://localhost:4000/";
        } else if (currentUrl.includes("dev0")) {
            return "http://sk-dev0-portal.skykick.com";
        } else if (currentUrl.includes("dev4")) {
            return "http://sk-dev4-portal.skykick.com";
        } else if (currentUrl.includes("dev")) {
            return "http://sk-dev9-portal.skykick.com";
        } else if (currentUrl.includes("qa2")) {
            return "http://sk-qa2-portal.skykick.com";
        } else if (currentUrl.includes("qa")) {
            return "http://sk-qa-portal.skykick.com";
        } else {
            return "https://portal.skykick.com";
        }
    }
}
