<div class="m-100 px-100">
    <h3
        class="sk-title-block mt-100 mb-200"
        translate="COMPONENTS.BRAND_ASSETS.TITLE"
    ></h3>
    <div class="col-lg-12 p-0 mb-150">
        <span translate="COMPONENTS.BRAND_ASSETS.CUSTOMIZE_TITLE"></span>
    </div>
    <form
        [formGroup]="skBrandAssetsForm"
        novalidate
        class="col-md-12"
        (ngSubmit)="onSubmit()"
    >
        <div>
            <div class="col-md-3 p-0 mb-150">
                <label
                    translate="COMPONENTS.BRAND_ASSETS.COMPANY_NAME"
                    class="font-medium"
                    for="skWhiteLabelSettingsCompanyNameInput"
                ></label>
                <input
                    class="form-control"
                    id="skWhiteLabelSettingsCompanyNameInput"
                    formControlName="skCompanyName"
                    name="skCompanyName"
                    type="text"
                />
            </div>
        </div>
        <div>
            <div class="col-md-3 p-0">
                <div class="row m-0">
                    <div class="col-md-8 p-0 mr-150">
                        <label
                            translate="COMPONENTS.BRAND_ASSETS.FAVICON"
                            class="font-medium"
                        ></label
                        >&nbsp;
                        <span
                            translate="COMMON.OPTIONAL"
                            class="text-muted"
                        ></span>
                    </div>
                    <div class="col-md-2 p-0 ml-100" *ngIf="skFaviconUrl">
                        <label
                            translate="COMPONENTS.BRAND_ASSETS.PREVIEW"
                            class="font-medium"
                            for="selectedFile"
                        ></label
                        >&nbsp;
                    </div>
                    <div class="col-md-8 p-0 mr-150">
                        <div class="input-width-max">
                            <input
                                #pageFaviconFile
                                id="selectedFile"
                                class="form-control"
                                type="file"
                                (change)="
                                    pageFaviconFileChange(pageFaviconFile.files)
                                "
                            />
                            <small
                                class="text-muted"
                                translate="COMPONENTS.BRAND_ASSETS.FAVICON_HELP_TEXT"
                            ></small>
                        </div>
                    </div>
                    <div class="col-md-2 p-0 ml-100" *ngIf="skFaviconUrl">
                        <img
                            [src]="skFaviconUrl"
                            alt="Page Favicon"
                            height="35"
                            width="auto"
                            class="responsive border"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="skBrandAssetsForm.errors && customValidationMessage !== ''">
            <div class="col-lg-3 p-2 alert alert-danger">
                {{ customValidationMessage | translate }}
            </div>
        </div>
        <div>
            <div class="col-lg-3 mt-100 p-0">
                <hr />
                <button
                    sk-spinner-button
                    [color]="'primary'"
                    class="mr-100"
                    [disabled]="
                        skBrandAssetsForm.invalid ||
                        skBrandAssetsForm.pristine ||
                        isLoading
                    "
                    [isSpinning]="isLoading"
                >
                    {{ "COMMON.SAVE" | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
