import { Component, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { Certificate } from "app/shared/models/Certificate";
import { setValidator } from "../abstract-control-helpers";

export type CustomUrlSelection = "domain" | "subdomain";

@Component({
    selector: "sk-custom-url",
    templateUrl: "./custom-url.component.html",
    styleUrls: ["./custom-url.component.scss"],
})
export class CustomUrlComponent implements OnInit {
    /** RegExp that is used to validate the skCustomDomain FormControl contains a valid input. */
    private readonly skCustomDomainControlValidatorRegex: RegExp =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    /** RegExp that is used to validate the skCustomSubDomain FormControl contains a valid input. */
    private readonly skCustomSubDomainControlValidatorRegex: RegExp =
        /^([a-zA-Z0-9-]{1,50})$/;

    @Input() skCustomUrl: UntypedFormGroup | undefined;
    @Input() skSubdomainHostName: string | undefined;
    /**
     * The maximum allowed file size of a certificate in Bytes.
     */
    private readonly _skMaxCertificateSize: number = 16777216;
    public sslCertShowPassphrase = false;
    public skEditSslCertificate = false;

    get skCustomUrlSelection(): CustomUrlSelection | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skCustomUrlSelection = this.skCustomUrl.get(
            "skCustomUrlSelection"
        ) as UntypedFormControl | null;
        if (!skCustomUrlSelection) {
            return null;
        }
        return skCustomUrlSelection.value as CustomUrlSelection;
    }
    get skSubDomainName(): string | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skSubDomainName = this.skCustomUrl.get(
            "skSubDomainName"
        ) as UntypedFormControl | null;
        if (!skSubDomainName) {
            return null;
        }
        return skSubDomainName.value as string;
    }
    get skDomainName(): string | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skDomainName = this.skCustomUrl.get(
            "skDomainName"
        ) as UntypedFormControl | null;
        if (!skDomainName) {
            return null;
        }
        return skDomainName.value as string;
    }
    get skCertificate(): Certificate | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skCertificate = this.skCustomUrl.get(
            "skCertificate"
        ) as UntypedFormControl | null;
        if (!skCertificate) {
            return null;
        }

        return skCertificate.value;
    }
    get hasCertificate(): boolean {
        const certificate = this.skCertificate;
        if (certificate) {
            return true;
        }
        return false;
    }
    get skSelectedSslCert(): File | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skSelectedSslCert = this.skCustomUrl.get(
            "skSelectedSslCert"
        ) as UntypedFormControl | null;
        if (!skSelectedSslCert) {
            return null;
        }
        return skSelectedSslCert.value as File;
    }
    set skSelectedSslCert(value: File | null) {
        if (!this.skCustomUrl) {
            return;
        }
        const skSelectedSslCert = this.skCustomUrl.get(
            "skSelectedSslCert"
        ) as UntypedFormControl | null;
        if (!skSelectedSslCert) {
            return;
        }
        this.skCustomUrl.patchValue({ skSelectedSslCert: value });
        skSelectedSslCert.updateValueAndValidity();
    }
    get skSslPassphrase(): string | null {
        if (!this.skCustomUrl) {
            return null;
        }
        const skSslPassphrase = this.skCustomUrl.get(
            "skSslPassphrase"
        ) as UntypedFormControl | null;
        if (!skSslPassphrase) {
            return null;
        }
        return skSslPassphrase.value as string;
    }
    set skSslPassphrase(value: string | null) {
        if (!this.skCustomUrl) {
            return;
        }
        const skSslPassphrase = this.skCustomUrl.get(
            "skSslPassphrase"
        ) as UntypedFormControl | null;
        if (!skSslPassphrase) {
            return;
        }
        this.skCustomUrl.patchValue({ skSslPassphrase: value ?? "" });
        skSslPassphrase.updateValueAndValidity();
    }
    get isSubDomainOption(): boolean {
        const skCustomUrlSelection = this.skCustomUrlSelection;
        return !skCustomUrlSelection || skCustomUrlSelection === "subdomain";
    }
    get sslCertFileName(): string | null {
        const skSelectedSslCert = this.skSelectedSslCert;
        if (skSelectedSslCert == null) {
            return null;
        }
        return skSelectedSslCert.name;
    }

    public validateRequirements(): void {
        if (!this.skCustomUrl) {
            return;
        }
        (
            this.skCustomUrl.get("skCustomUrlSelection") as UntypedFormControl
        ).updateValueAndValidity();

        const skSubDomainName = this.skCustomUrl.get(
            "skSubDomainName"
        ) as UntypedFormControl | null;
        const skDomainName = this.skCustomUrl.get(
            "skDomainName"
        ) as UntypedFormControl | null;
        const skSelectedSslCert = this.skCustomUrl.get(
            "skSelectedSslCert"
        ) as UntypedFormControl | null;
        const skSslPassphrase = this.skCustomUrl.get(
            "skSslPassphrase"
        ) as UntypedFormControl | null;

        if (skSubDomainName) {
            skSubDomainName.markAsDirty();
        }
        if (skDomainName) {
            skDomainName.markAsDirty();
        }
        if (skSelectedSslCert) {
            skSelectedSslCert.markAsDirty();
        }
        if (skSslPassphrase) {
            skSslPassphrase.markAsDirty();
        }
    }

    /**
     * Updates the FormGroup skSelectedSslCert with the selected file.
     * @param sslCertFilePath The User's selected filepath.
     */
    public sslCertificateFileChanged(
        selectedFiles: FileList | null | undefined
    ): void {
        if (!selectedFiles || selectedFiles.length <= 0) {
            return;
        }
        const selectedFile: File | null = selectedFiles.item(0);
        if (!selectedFile || selectedFile.size > this._skMaxCertificateSize) {
            return;
        }

        if (!this.skCustomUrl) {
            return;
        }
        this.skCustomUrl.patchValue({ skSelectedSslCert: selectedFile });
    }

    public ngOnInit(): void {
        this.handleFormChanges();
    }

    /**
     * Listens for changes to the skCustomUrlSelection FormControl. When a change
     * occurs, the child FormControl's of the skCustomUrl FormGroup will update their
     * validation based off the current skCustomUrlSelection value.
     */
    private handleFormChanges(): void {
        const skCustomUrl = this.skCustomUrl;
        if (!skCustomUrl) {
            throw new Error("skCustomUrl FormGroup is not initialized");
        }

        // Conditionally modify the CustomUrlSelection validators
        const skCustomUrlSelection = skCustomUrl.get(
            "skCustomUrlSelection"
        ) as UntypedFormControl;
        skCustomUrlSelection.valueChanges.subscribe(
            (value: CustomUrlSelection) => {
                const skSubDomainName = skCustomUrl.get("skSubDomainName");
                const skDomainName = skCustomUrl.get("skDomainName");
                setValidator(
                    skSubDomainName,
                    value === "subdomain"
                        ? [
                              Validators.required,
                              Validators.pattern(
                                  this.skCustomSubDomainControlValidatorRegex
                              ),
                          ]
                        : []
                );
                setValidator(
                    skDomainName,
                    value === "domain"
                        ? [
                              Validators.required,
                              Validators.pattern(
                                  this.skCustomDomainControlValidatorRegex
                              ),
                          ]
                        : []
                );

                const skCertificate = skCustomUrl.get(
                    "skCertificate"
                ) as AbstractControl;
                const skSelectedSslCert = skCustomUrl.get(
                    "skSelectedSslCert"
                ) as AbstractControl;
                const skSslPassphrase = skCustomUrl.get(
                    "skSslPassphrase"
                ) as AbstractControl;
                const skSelectedSslCertHasValue = !!skSelectedSslCert.value;
                const skSslPassphraseHasValue = !!skSslPassphrase.value;

                /*
            Certificates are required under two conditions:
            skCustomUrlSelection is the 'domain' option && skCertificate is does not have a value
                - The above happens when the user is uploading a cert for the first time.
            skCustomUrlSelection is the 'domain' option && skSelectedSslCertHasValue as well as skSslPassphraseHasValue
                - The above happens when the user is editing the cert when they have already uploaded a cert in the past.
            */
                const certificateRequired =
                    value === "domain" &&
                    (!skCertificate.value ||
                        skSelectedSslCertHasValue ||
                        skSslPassphraseHasValue);

                setValidator(
                    skSelectedSslCert,
                    certificateRequired ? Validators.required : []
                );
                setValidator(
                    skSslPassphrase,
                    certificateRequired ? Validators.required : []
                );
            }
        );
        skCustomUrlSelection.updateValueAndValidity();
    }
}
