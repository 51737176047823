import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { InitializationService } from "../login/initialization.service";

export const HasBackupPermissionGuard: CanActivateFn = () => {
    const user = inject(AbstractUserProvider).getCurrentUser();
    const hasPermission = user.hasPermission("Cloud_Backup_Full_Access");
    if (!hasPermission) {
        inject(Window).location.href = `${inject(
            InitializationService
        ).getLoginRedirect()}/error`;
        return false;
    }
    return true;
};
