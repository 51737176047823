import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupportDetails } from "app/shared/models/SupportDetails";
import { SupportOption } from "../../shared/customer-support/customer-support.component";

@Component({
    selector: "sk-cloud-backup-preview-dialog",
    templateUrl: "./cloud-backup-preview-dialog.component.html",
    styleUrls: ["./cloud-backup-preview-dialog.component.scss"],
})
export class CloudBackupPreviewDialogComponent implements OnInit {
    public readonly users = [
        {
            User: "Barbara Anderson",
            Email: "Barbara.Anderson@customerdomain.com",
            Backup: true,
        },
        {
            User: "Charles Hernandez",
            Email: "Charles.Hernandez@customerdomain.com",
            Backup: true,
        },
        {
            User: "David Jackson",
            Email: "David.Jackson@customerdomain.com",
            Backup: true,
        },
        {
            User: "James Smith",
            Email: "James.Smith@customerdomain.com",
            // The Khal lives on the edge and claims he doesn't need backup.
            Backup: false,
        },
        {
            User: "Linda Miller",
            Email: "Linda.Miller@customerdomain.com",
            Backup: true,
        },
    ];

    @Input() public skPublished: boolean = false;
    @Input() public skCompanyName: string = "";
    @Input() public skLogoUrl: string = "";
    @Input() public skSupportOption: SupportOption = "support-url";
    @Input() public skCustomerSupportInfo!: SupportDetails | string;
    public get skSupportDetails(): SupportDetails {
        if (!this.skCustomerSupportInfo) {
            throw new Error(
                "Invalid skCustomerSupportInfo value. null or undefined is not allowed."
            );
        }
        return this.skCustomerSupportInfo as SupportDetails;
    }
    public get skSupportUrl(): string {
        if (!this.skCustomerSupportInfo) {
            throw new Error(
                "Invalid skCustomerSupportInfo value. null or undefined is not allowed."
            );
        }
        return this.skCustomerSupportInfo as string;
    }

    constructor(public readonly modal: NgbActiveModal) {}

    ngOnInit(): void {}
}
