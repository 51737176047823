<form [formGroup]="skCustomerSupport">
    <div class="row px-0 mx-0">
        <div class="col-6 p-0">
            <fieldset class="mb-100">
                <h4 class="card-title mb-100">
                    <span translate="COMPONENTS.CUSTOMER_SUPPORT.TITLE"></span>
                    <button
                        href="#"
                        class="btn sk-btn-icon sk-btn-inline-icon bd-popover border-0"
                        [ngbTooltip]="
                            'COMPONENTS.CUSTOMER_SUPPORT.HELP_TEXT' | translate
                        "
                        placement="right"
                    >
                        <i class="material-icons md-22 sk-help">help_outline</i>
                    </button>
                </h4>
                <div class="form-check d-block">
                    <input
                        id="skCustomerSupportDetailsUrlRadio"
                        formControlName="skSupportOptions"
                        name="skSupportOptions"
                        type="radio"
                        value="support-url"
                        [checked]="isCustomerSupportUrlOption"
                        class="form-check-input"
                    />
                    <label
                        class="form-check-label"
                        translate="COMPONENTS.CUSTOMER_SUPPORT.URL"
                        for="skCustomerSupportDetailsUrlRadio"
                    ></label>
                    <div *ngIf="isCustomerSupportUrlOption">
                        <input
                            id="skCustomerSupportDetailsUrlInput"
                            class="form-control"
                            formControlName="skSupportUrl"
                            placeholder="{{
                                'COMPONENTS.CUSTOMER_SUPPORT.URL_HINT'
                                    | translate
                            }}"
                        />
                    </div>
                </div>
                <div class="form-check d-block">
                    <input
                        id="skCustomerSupportDetailsRadio"
                        formControlName="skSupportOptions"
                        name="skSupportOptions"
                        type="radio"
                        value="support-details"
                        [checked]="!isCustomerSupportUrlOption"
                        class="form-check-input"
                    />
                    <label
                        class="form-check-label"
                        translate="COMPONENTS.CUSTOMER_SUPPORT.DETAILS"
                        for="skCustomerSupportDetailsRadio"
                    ></label>

                    <div *ngIf="!isCustomerSupportUrlOption" class="mt-50">
                        <fieldset class="mb-100">
                            <label
                                translate="COMPONENTS.CUSTOMER_SUPPORT.EMAIL_TITLE"
                                class="font-medium"
                                for="skCustomerSupportDetailsEmailInput"
                            ></label>
                            <input
                                id="skCustomerSupportDetailsEmailInput"
                                class="form-control"
                                formControlName="skSupportEmail"
                                placeholder="{{
                                    'COMPONENTS.CUSTOMER_SUPPORT.EMAIL_HINT'
                                        | translate
                                }}"
                            />
                        </fieldset>
                        <fieldset class="mb-100">
                            <label
                                translate="COMPONENTS.CUSTOMER_SUPPORT.PHONE_TITLE"
                                class="font-medium"
                                for="skCustomerSupportDetailsPhoneInput"
                            ></label>
                            <input
                                id="skCustomerSupportDetailsPhoneInput"
                                class="form-control"
                                formControlName="skSupportPhone"
                                placeholder="{{
                                    'COMPONENTS.CUSTOMER_SUPPORT.PHONE_HINT'
                                        | translate
                                }}"
                            />
                        </fieldset>
                        <fieldset class="mb-100">
                            <label
                                translate="COMPONENTS.CUSTOMER_SUPPORT.HOURS_TITLE"
                                class="font-medium"
                                for="skCustomerSupportDetailsHoursInput"
                            ></label>
                            <input
                                id="skCustomerSupportDetailsHoursInput"
                                class="form-control"
                                formControlName="skSupportHours"
                                placeholder="{{
                                    'COMPONENTS.CUSTOMER_SUPPORT.HOURS_HINT'
                                        | translate
                                }}"
                            />
                            <small
                                class="sk-form-text text-muted"
                                translate="COMPONENTS.CUSTOMER_SUPPORT.HOURS_SAMPLE"
                            ></small>
                        </fieldset>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
</form>
