import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { BrandSettings } from "../../../shared/BrandSettings";
import { BackupPartnerSettingsRequest } from "../../../shared/models/BackupPartnerSettingsRequest";
import { BackupWhiteLabelSettings } from "../../../shared/models/BackupWhiteLabelSettings";
import { BrandSettingsRequest } from "../../../shared/models/BrandSettingsRequest";
import { Certificate } from "../../../shared/models/Certificate";
import { FileUploadRequest } from "../../../shared/models/FileUploadRequest";
import { FileUploadResponse } from "../../../shared/models/FileUploadResponse";
import { MarketplaceConfigPartnerSettingsRequest } from "../../../shared/models/MarketplaceConfigPartnerSettingsRequest";
import { MarketplaceConfigWhiteLabelSettings } from "../../../shared/models/MarketplaceConfigWhiteLabelSettings";

@Injectable({
    providedIn: "root",
})
export class WhiteLabelDataService {
    constructor(private readonly httpClient: HttpClient) {}

    /**
     * @returns BackupWhiteLabelSettings or null if no WhiteLabelBackupSettings are found.
     */
    public getBackupSettings(): Observable<BackupWhiteLabelSettings | null> {
        return this.httpClient.get<BackupWhiteLabelSettings | null>(
            `${environment.sewr}/pipe/papi/partnersettings/backup`
        );
    }

    /**
     * @returns Certificate information or null if no Certificate was uploaded.
     */
    public saveBackupSettings(
        backupPartnerSettingsRequest: BackupPartnerSettingsRequest
    ): Observable<Certificate | null> {
        return this.httpClient.post<Certificate | null>(
            `${environment.sewr}/pipe/papi/partnersettings/backup`,
            backupPartnerSettingsRequest
        );
    }

    public getMarketplaceConfigSettings(): Observable<MarketplaceConfigWhiteLabelSettings | null> {
        return this.httpClient.get<MarketplaceConfigWhiteLabelSettings | null>(
            `${environment.sewr}/pipe/papi/partnersettings/marketplacecfg`
        );
    }

    public saveMarketplaceConfigSettings(
        marketplaceConfigPartnerSettingsRequest: MarketplaceConfigPartnerSettingsRequest
    ): Observable<Certificate | null> {
        return this.httpClient.post<Certificate | null>(
            `${environment.sewr}/pipe/papi/partnersettings/marketplacecfg`,
            marketplaceConfigPartnerSettingsRequest
        );
    }

    public getBrandSettings(): Observable<BrandSettings | null> {
        return this.httpClient.get<BrandSettings | null>(
            `${environment.sewr}/pipe/papi/partnersettings/brand`
        );
    }

    /**
     * Save brand assets for a partner
     */
    public saveBrandSettings(
        brandSettings: BrandSettingsRequest
    ): Observable<BrandSettings | null> {
        return this.httpClient.post<BrandSettingsRequest | null>(
            `${environment.sewr}/pipe/papi/partnersettings/brand`,
            brandSettings
        );
    }

    /**
     * Upload a file to blob storage
     */
    public uploadFileToBlob(
        fileUploadRequest: FileUploadRequest
    ): Observable<FileUploadResponse | null> {
        return this.httpClient.post<FileUploadResponse | null>(
            `${environment.sewr}/pipe/papi/partner/files`,
            fileUploadRequest
        );
    }
}
