<div class="modal-header">
    <h4 class="modal-title">{{ Title }}</h4>
    <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss(false)"
    ></button>
</div>
<div class="modal-body">
    {{ Message }}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">
        {{ "COMPONENTS.PUBLISH.UNPUBLISH_LINK" | translate }}
    </button>
    <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.close(false)"
    >
        {{ "COMMON.CANCEL" | translate }}
    </button>
</div>
