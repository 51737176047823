import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SupportDetails } from "app/shared/models/SupportDetails";
import { SupportOption } from "../../shared/customer-support/customer-support.component";

@Component({
    selector: "sk-marketplace-config-preview-dialog",
    templateUrl: "./marketplace-config-preview-dialog.component.html",
    styleUrls: ["./marketplace-config-preview-dialog.component.scss"],
})
export class MarketplaceConfigPreviewDialogComponent implements OnInit {
    public readonly users = [
        {
            User: "Barbara Anderson",
            Email: "Barbara.Anderson@customerdomain.com",
            Backup: true,
        },
    ];
    @Input() public skPublished: boolean = false;
    @Input() public skCompanyName: string = "";
    @Input() public skLogoUrl: string = "";
    @Input() public skSupportOption: SupportOption = "support-url";
    @Input() public skCustomerSupportInfo!: SupportDetails | string;
    public get skSupportDetails(): SupportDetails {
        if (!this.skCustomerSupportInfo) {
            throw new Error(
                "Invalid skCustomerSupportInfo value. null or undefined is not allowed."
            );
        }
        return this.skCustomerSupportInfo as SupportDetails;
    }
    public get skSupportUrl(): string {
        if (!this.skCustomerSupportInfo) {
            throw new Error(
                "Invalid skCustomerSupportInfo value. null or undefined is not allowed."
            );
        }
        return this.skCustomerSupportInfo as string;
    }

    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}
}
