<div class="row flex-fill">
    <fieldset class="mb-100 col">
        <div class="list-group">
            <div class="list-group-item">
                <h4 translate="COMPONENTS.PUBLISH.TITLE"></h4>
            </div>
            <div class="list-group-item">
                <label
                    translate="COMPONENTS.PUBLISH.STATUS_INDICATOR_TEXT"
                    class="font-medium"
                ></label>
                <span
                    [translate]="
                        skPublished
                            ? 'COMPONENTS.PUBLISH.PUBLISHED_STATUS_BADGE'
                            : 'COMPONENTS.PUBLISH.DRAFT_STATUS_BADGE'
                    "
                    class="badge label label-default float-end"
                    [class.label-success]="skPublished"
                    id="skPublishPublishStatusBadge"
                ></span>
            </div>
            <div class="list-group-item">
                <label
                    class="font-medium"
                    translate="COMPONENTS.PUBLISH.VISIBILITY_INDICATOR_TEXT"
                ></label>
                <span
                    [translate]="
                        skPublished
                            ? 'COMPONENTS.PUBLISH.PUBLIC_VISIBILITY_BADGE'
                            : 'COMPONENTS.PUBLISH.PRIVATE_VISIBILITY_BADGE'
                    "
                    class="badge label label-default float-end"
                    id="skPublishPublishVisibilityBadge"
                ></span>
            </div>
            <div class="list-group-item">
                <label
                    translate="COMPONENTS.PUBLISH.CUSTOMER_AUTH_TEXT"
                    class="mr-100 font-medium"
                ></label>
                <span
                    [disableTooltip]="skPublished"
                    [ngbTooltip]="'COMPONENTS.PUBLISH.SKYKICK_AUTH_BADGE_TOOLTIP_TEXT' | translate"
                    [translate]="
                        skPublished
                            ? 'COMPONENTS.PUBLISH.M365_AUTH_BADGE'
                            : 'COMPONENTS.PUBLISH.SKYKICK_AUTH_BADGE'
                    "
                    class="badge label label-default float-end"
                    id="skPublishCustomerAuthenticationBadge"
                ></span>
            </div>
            <div class="list-group-item">
                <button
                    id="skPublishPublishButton"
                    class="btn btn-primary float-left mr-100"
                    translate="COMPONENTS.PUBLISH.PUBLISH_BUTTON"
                    [disabled]="skFirstSave || skPublished"
                    (click)="publish.emit()"
                    type="button"
                ></button>
                <button
                    id="skPublishPreviewButton"
                    class="btn btn-outline-secondary sk-btn-combo float"
                    [disabled]="skFirstSave"
                    (click)="preview.emit()"
                    type="button"
                >
                    <span translate="COMPONENTS.PUBLISH.PREVIEW_BUTTON"></span>
                </button>
                <button
                    class="btn btn-link float-end"
                    id="skPublishUnpublishButton"
                    translate="COMPONENTS.PUBLISH.UNPUBLISH_LINK"
                    (click)="confirmUnpublish()"
                    type="button"
                    [hidden]="!skPublished"
                ></button>
            </div>
        </div>
    </fieldset>
</div>
