import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CookieService } from "ngx-cookie-service";

@Component({
    selector: "sk-welcome-modal",
    templateUrl: "./welcome-modal.component.html",
    styleUrls: ["./welcome-modal.component.scss"],
})
export class WelcomeModalComponent implements OnInit {
    constructor(
        private readonly modal: NgbActiveModal,
        private readonly cookieService: CookieService
    ) {}

    ngOnInit(): void {}

    public savePreviewModalState(): void {
        this.cookieService.set("preview-full-width", "true");
        this.modal.close();
    }
}
