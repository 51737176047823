import { AbstractControl, ValidatorFn } from "@angular/forms";

/** Helper function for updating an AbstractControl's validator. */
export function setValidator(
    control: AbstractControl | null,
    validators: ValidatorFn | Array<ValidatorFn> | null
): void {
    if (!control) {
        return;
    }
    control.setValidators(validators);
    control.updateValueAndValidity();
    control.markAsPristine();
}
