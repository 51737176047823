import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
    NgbModalModule,
    NgbPopoverModule,
    NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { ButtonsModule } from "@skykick/core";
import { SharedModule } from "../../shared/shared.module";
import { CloudBackupPreviewDialogComponent } from "./cloud-backup/cloud-backup-preview-dialog/cloud-backup-preview-dialog.component";
import { CloudBackupComponent } from "./cloud-backup/cloud-backup.component";
import { MarketplaceConfigPreviewDialogComponent } from "./marketplace-config/marketplace-config-preview-dialog/marketplace-config-preview-dialog.component";
import { MarketplaceConfigComponent } from "./marketplace-config/marketplace-config.component";
import { SettingsComponent } from "./settings/settings.component";
import { CustomUrlComponent } from "./shared/custom-url/custom-url.component";
import { CustomerSupportComponent } from "./shared/customer-support/customer-support.component";
import { PublishComponent } from "./shared/publish/publish.component";
import { WhiteLabelComponent } from "./whitelabel.component";
import { WhiteLabelRoutes } from "./whitelabel.routes";

@NgModule({
    declarations: [
        WhiteLabelComponent,
        CloudBackupComponent,
        SettingsComponent,
        MarketplaceConfigComponent,
        CustomerSupportComponent,
        CustomUrlComponent,
        PublishComponent,
        CloudBackupPreviewDialogComponent,
        MarketplaceConfigPreviewDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        ButtonsModule,
        RouterModule.forChild(WhiteLabelRoutes),
    ],
    exports: [
        WhiteLabelComponent,
        CloudBackupComponent,
        SettingsComponent,
        MarketplaceConfigComponent,
        CustomerSupportComponent,
        CustomUrlComponent,
        PublishComponent,
        RouterModule,
    ],
})
export class WhiteLabelModule {}
