import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { CookieService } from "ngx-cookie-service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: "root",
})
export class BannerSessionService {
    private backupLandingBannerKey: string =
        "skykick:syndication:backup:landing:banner";
    private readonly skIdTokenKey = "sk_id_token";

    constructor(
        private readonly localStorage: LocalStorageService,
        private readonly cookieService: CookieService
    ) {}

    closeBackupLandingBannerForCurrentSession() {
        const currentSessionId = this.getSessionId();
        if (currentSessionId)
            this.localStorage.set(
                this.backupLandingBannerKey,
                currentSessionId
            );
    }

    removeBackupLandingBannerKeyOnNewSession(): void {
        const currentSessionId = this.getSessionId();

        if (
            currentSessionId &&
            currentSessionId !==
                this.localStorage.get(this.backupLandingBannerKey)
        ) {
            this.localStorage.remove(this.backupLandingBannerKey);
        }
    }

    checkIfBackupLandingFeedbackWasClosedForSession = () =>
        !this.localStorage.get(this.backupLandingBannerKey);

    /**
     * The 'sid' is unique for each session
     * @returns sessionId
     */
    private getSessionId(): string | null {
        const jwtAccessToken = this.cookieService.get(this.skIdTokenKey);
        if (!jwtAccessToken) {
            return null;
        }

        const decodedJwt = jwtDecode<any>(jwtAccessToken);

        return decodedJwt["sid"];
    }
}
