import { HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import {
    TranslateLoader,
    TranslateModule,
    TranslateService,
} from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AppComponent } from "./app.component";
import { ApplicationRoutes } from "./app.routes";
import { CoreModule } from "./core/core.module";
import {
    HttpLoaderFactory,
    LanguageLoader,
} from "./core/i18n/translation-loader-factory";
import { SharedModule } from "./shared/shared.module";
import { WhiteLabelModule } from "./syndication/whitelabel/whitelabel.module";
import { WelcomeModalComponent } from "./utility/new-experience-modal/welcome-modal/welcome-modal.component";

/**
 *
 */
@NgModule({
    declarations: [AppComponent, WelcomeModalComponent],
    imports: [
        // @angular modules
        BrowserModule,
        BrowserAnimationsModule,
        // application modules
        CoreModule,
        SharedModule,
        WhiteLabelModule,
        // module routing
        RouterModule.forRoot(ApplicationRoutes, {
            enableTracing: false,
            paramsInheritanceStrategy: "always",
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: LanguageLoader,
            multi: true,
            deps: [TranslateService, CookieService],
        },
    ],
})
export class AppModule {}
