import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export function LanguageLoader(
    translate: TranslateService,
    cookieService: CookieService
): () => Promise<void> {
    return async () =>
        new Promise<void>((resolve) => {
            // fallback language
            translate.setDefaultLang("en");
            const cookieLang = cookieService.get("skLanguageCode");
            const browserLang = cookieLang || translate.getBrowserLang();

            // TODO: Get together with UX. What are the standards for translations. Get an updated list of stuff.
            translate
                .use(browserLang?.match(/en|ja|sq|de/) ? browserLang : "en")
                .subscribe((_) => resolve());
        });
}
