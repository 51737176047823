import { Route, Routes } from "@angular/router";
import { HasBackupPermissionGuard } from "app/core/routing/guards/has-backup-permission.guard";
import { CloudBackupComponent } from "./cloud-backup/cloud-backup.component";
import { MarketplaceConfigComponent } from "./marketplace-config/marketplace-config.component";
import { SettingsComponent } from "./settings/settings.component";
import { WhiteLabelComponent } from "./whitelabel.component";

export const WhiteLabelRoutes: Routes = [
    {
        path: "brandedservices",
        component: WhiteLabelComponent,
        canActivate: [HasBackupPermissionGuard],
        children: [
            {
                path: "",
                redirectTo: "backup",
                pathMatch: "full",
            },
            {
                path: "backup",
                component: CloudBackupComponent,
                data: {
                    type: "secondary",
                    name: "COMMON.CLOUD_BACKUP",
                },
            },
            {
                path: "marketplace",
                component: MarketplaceConfigComponent,
                data: {
                    type: "secondary",
                    name: "COMMON.MARKETPLACE_ORDER",
                },
            },
            {
                path: SettingsComponent.ComponentName,
                component: SettingsComponent,
                data: {
                    type: "secondary",
                    name: "COMMON.SETTINGS",
                },
            },
        ] as Array<Route>,
    },
];
