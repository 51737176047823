import { Component, Input, OnInit } from "@angular/core";
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { setValidator } from "../abstract-control-helpers";

export type SupportOption = "support-url" | "support-details";

@Component({
    selector: "sk-customer-support",
    templateUrl: "./customer-support.component.html",
    styleUrls: ["./customer-support.component.scss"],
})
export class CustomerSupportComponent implements OnInit {
    @Input() skCustomerSupport: UntypedFormGroup | undefined;
    constructor() {}

    get skSupportOptions(): SupportOption | null {
        if (!this.skCustomerSupport) {
            return null;
        }
        const skSupportOptions = this.skCustomerSupport.get(
            "skSupportOptions"
        ) as UntypedFormControl | null;
        if (!skSupportOptions) {
            return null;
        }
        return skSupportOptions.value as SupportOption;
    }
    get skSupportUrl(): string | null {
        if (!this.skCustomerSupport) {
            return null;
        }
        const skSupportUrl = this.skCustomerSupport.get(
            "skSupportUrl"
        ) as UntypedFormControl | null;
        if (!skSupportUrl) {
            return null;
        }
        return skSupportUrl.value as string;
    }
    get skSupportEmail(): string | null {
        if (!this.skCustomerSupport) {
            return null;
        }
        const skSupportEmail = this.skCustomerSupport.get(
            "skSupportEmail"
        ) as UntypedFormControl | null;
        if (!skSupportEmail) {
            return null;
        }
        return skSupportEmail.value as string;
    }
    get skSupportPhone(): string | null {
        if (!this.skCustomerSupport) {
            return null;
        }
        const skSupportPhone = this.skCustomerSupport.get(
            "skSupportPhone"
        ) as UntypedFormControl | null;
        if (!skSupportPhone) {
            return null;
        }
        return skSupportPhone.value as string;
    }
    get skSupportHours(): string | null {
        if (!this.skCustomerSupport) {
            return null;
        }
        const skSupportHours = this.skCustomerSupport.get(
            "skSupportHours"
        ) as UntypedFormControl | null;
        if (!skSupportHours) {
            return null;
        }
        return skSupportHours.value as string;
    }
    get isCustomerSupportUrlOption(): boolean {
        return this.skSupportOptions === "support-url";
    }

    public validateRequirements(): void {
        if (!this.skCustomerSupport) {
            return;
        }
        (
            this.skCustomerSupport.get("skSupportOptions") as UntypedFormControl
        ).updateValueAndValidity();

        const skSupportUrl = this.skCustomerSupport.get(
            "skSupportUrl"
        ) as UntypedFormControl | null;
        const skSupportEmail = this.skCustomerSupport.get(
            "skSupportEmail"
        ) as UntypedFormControl | null;
        const skSupportPhone = this.skCustomerSupport.get(
            "skSupportPhone"
        ) as UntypedFormControl | null;
        const skSupportHours = this.skCustomerSupport.get(
            "skSupportHours"
        ) as UntypedFormControl | null;
        if (skSupportUrl) {
            skSupportUrl.markAsDirty();
        }
        if (skSupportEmail) {
            skSupportEmail.markAsDirty();
        }
        if (skSupportPhone) {
            skSupportPhone.markAsDirty();
        }
        if (skSupportHours) {
            skSupportHours.markAsDirty();
        }
    }

    public ngOnInit(): void {
        this.handleFormChanges();
    }

    /**
     * Listens for changes to the skSupportOptions FormControl. When a change
     * occurs, the child FormControl's of the skCustomerSupport FormGroup will update their
     * validation based off the current skSupportOptions value.
     */
    private handleFormChanges(): void {
        const skCustomerSupport = this.skCustomerSupport;
        if (!skCustomerSupport) {
            throw new Error("skCustomUrl FormGroup is not initialized");
        }

        // Conditionally modify the SupportOptions validators
        const skSupportOptions = skCustomerSupport.get(
            "skSupportOptions"
        ) as UntypedFormControl;
        skSupportOptions.valueChanges.subscribe((value: SupportOption) => {
            const skSupportEmail = skCustomerSupport.get("skSupportEmail");
            const skSupportPhone = skCustomerSupport.get("skSupportPhone");
            const skSupportHours = skCustomerSupport.get("skSupportHours");
            const skSupportUrl = skCustomerSupport.get("skSupportUrl");
            setValidator(
                skSupportEmail,
                value === "support-details" ? Validators.required : []
            );
            setValidator(
                skSupportPhone,
                value === "support-details" ? Validators.required : []
            );
            setValidator(
                skSupportHours,
                value === "support-details" ? Validators.required : []
            );
            setValidator(
                skSupportUrl,
                value === "support-url"
                    ? [
                          Validators.required,
                          Validators.pattern(
                              /(https?|ftp):\/\/([^\s/?.#]+.?)+(\/[^\s]*)?$/i
                          ),
                      ]
                    : []
            );
        });
        skSupportOptions.updateValueAndValidity();
    }
}
