import { SkyKickUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { SyndicationEnvironment } from "./syndication-environment";

export const environment: SyndicationEnvironment = {
    production: false,
    userProvider: SkyKickUserProvider,
    sewr: "https://sk-dev4-routing.skykick.com",
    licensesAssignmentBaseAddress: "https://sk-dev-platform-apis.skykick.com",
    dataDogTracing: {
        enabled: true,
        clientToken: "pubbbceeab178a64814414bcd851804ab7f",
        serviceName: "skykick-branded-services",
        applicationId: "7ea53158-a075-442f-bcdb-47a8510e3f30",
        defaultPrivacyView: "mask-user-input",
        sessionSampleRate: 50,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        environment: "dev",
    },
};
