import { Component, OnInit, ViewChild } from "@angular/core";
import Intercom from "@intercom/messenger-js-sdk";
import { TranslateService } from "@ngx-translate/core";
import { NavigationArea, PlatformNavigationComponent } from "@skykick/core";
import { AbstractUserProvider } from "@skykick/platform-identity-auth-auth0-angular";
import { environment } from "environments/environment";
import { WhiteLabelTracingService } from "./syndication/whitelabel/services/whitelabel-tracing.service";

@Component({
    // tslint:disable-next-line: component-selector
    selector: "[sk-root]",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    constructor(
        readonly translateService: TranslateService,
        private userService: AbstractUserProvider,
        private tracingService: WhiteLabelTracingService
    ) {}

    @ViewChild(PlatformNavigationComponent)
    public skPlatformNavigation: PlatformNavigationComponent;

    async ngOnInit(): Promise<void> {
        const user = this.userService.getCurrentUser();

        Intercom({
            app_id: "itg40p5r",
            email: user.email,
            user_id: user.userId,
            partner_id: user.partnerId,
            name: user.fullName,
            avatar: {
                type: "avatar",
                image_url: user.picture,
            },
            is_admin: user.hasPermission("partnerportaladmin"),
            updateOnRouterChange: true,
            horizontal_padding: 20,
            vertical_padding: 20,
        });

        this.tracingService.initializeWhiteLabelTracing(
            environment.dataDogTracing
        );
        this.tracingService.setUserData(user);
    }

    async ngAfterViewInit(): Promise<void> {
        this.skPlatformNavigation.SkNavigationArea =
            NavigationArea.BrandedServices;
    }
}
