import {
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
    BannerModule,
    PartnerModule,
    ToastsModule,
    UtilityModule,
} from "@skykick/core";
import {
    AbstractUserProvider,
    AuthModule,
} from "@skykick/platform-identity-auth-auth0-angular";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../environments/environment";
import { InitializationService } from "./routing/login/initialization.service";

@NgModule({
    imports: [
        // @skykick/core modules
        BannerModule,
        PartnerModule,
        AuthModule.forRoot({
            LicenseAssignmentServiceBaseUrl:
                environment.licensesAssignmentBaseAddress +
                "/licenseassignment/api/v1",
            SewrSystemDomain: environment.sewr,
        }),
        UtilityModule.forRoot({
            sewrSystemDomain: environment.sewr,
        }),
        // 3rd party modules
        TranslateModule,
        ToastsModule,
        // Application modules
    ],
    providers: [
        CookieService,
        // Service for retrieving and authorizing http requests automatically
        {
            provide: AbstractUserProvider,
            useExisting: environment.userProvider,
        },
        // Register a temporary fix for expired login redirect handler. NOTE: This needs to be addressed ASAP. CM #76699 / PS #70222
        {
            provide: APP_INITIALIZER,
            useFactory:
                (initializationService: InitializationService) => async () =>
                    initializationService.run(),
            deps: [InitializationService, TranslateService],
            multi: true,
        },
        // Service for providing application translations. (translations located in /assets/i18n)
        TranslateService,
        // Service for displaying toast messages to the user.
        ToastrService,
        /* ADDITIONAL GLOBAL SERVICES */
        { provide: "Window", useValue: window },
        provideHttpClient(withInterceptorsFromDi()),
    ],
    exports: [BannerModule, TranslateModule],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            // CoreModule should only be loaded a single time within AppModule.
            throw new Error("CoreModule already loaded.");
        }
    }
}
