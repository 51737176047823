import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "sk-confirmation-modal",
    templateUrl: "./confirmation-modal.component.html",
    styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent implements OnInit {
    @Input() Title: string;
    @Input() Message: string;
    constructor(public modal: NgbActiveModal) {}

    ngOnInit(): void {}
}
